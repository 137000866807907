/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, ContactForm, Image } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"RSVP"}>
        <SiteHeader />

        <Column className="--center pb--80 pt--80" name={"rsvp-2"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s5 --center el--1" anim={"2"} animS={"5"} style={{"maxWidth":1090}} columns={"1"}>
            
            <ColumnWrapper className="pt--40">
              
              <Title className="title-box title-box--center fs--102 mt--10" content={"RSVP"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":600}} content={"Liefste ouders, wij hebben een wedding-nanny &nbsp;voorzien op onze trouw tot 18u<br>zodat jullie tenvolle kunnen genieten van deze dag ♡<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--0 pt--0" name={"rsvp"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <ContactForm className="--shape2 --shape3 --style3 ff--2 fs--18" style={{"maxWidth":740}} action={"/contact"} fields={[{"name":"Naam","type":"text","required":true,"placeholder":"Naam"},{"name":"Aantal personen","type":"text","required":true,"placeholder":"Aantal personen"},{"name":"Kinderen","type":"text","required":true,"placeholder":"Kinderen"},{"name":"Send","type":"submit"}]}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--60 pt--60" name={"p255wi122a"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--center">
              
              <Text className="text-box" style={{"maxWidth":654}} content={"Cadeautip &nbsp;✉️<br>BE59 7360 5728 4426<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--60 pt--60" name={"photogallery"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s5 --center el--1" anim={"2"} animS={"5"} style={{"maxWidth":1150}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Image alt={""} src={"https://cdn.swbpg.com/t/30673/cbc5caf56a8e4b7a870fa008ced03369_s=660x_.jpg"} sizes={"100vw"} style={{"maxWidth":905}} srcSet={"https://cdn.swbpg.com/t/30673/cbc5caf56a8e4b7a870fa008ced03369_s=350x_.jpg 350w, https://cdn.swbpg.com/t/30673/cbc5caf56a8e4b7a870fa008ced03369_s=660x_.jpg 660w, https://cdn.swbpg.com/t/30673/cbc5caf56a8e4b7a870fa008ced03369_s=860x_.jpg 860w"} position={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--80 pt--40" name={"footer-2"}>
          
          <ColumnWrap className="column__flex --center el--1" anim={null} animS={null} style={{"maxWidth":1090}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--center fs--102 mt--10" content={"We kijken ernaar uit<br>om u te zien!"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}